/* webpackChunkName: "MenuCategory" */

import { containerClass, descriptionClass, itemsClass, nameClass } from "./index.module.scss";

import MenuCatgoryItem from './item';
import PropTypes from 'prop-types';
import React from 'react';

export default class MenuCatgoryComponent extends React.Component {
  render() {
    const { id, name, items, description } = this.props;

    const entries = items.map((item, index) => {
      return <MenuCatgoryItem key={index} name={item.name} description={item?.description?.description} price={item.price}/>
    });

    return (
      <section id={id}>
        <div className={containerClass}>
          <h2 className={nameClass}>{name}</h2>
          {( description?.description &&
            <div className={descriptionClass}>{description?.description}</div>
          )}
          <div className={itemsClass}>
            {entries}
          </div>
        </div>
      </section>
    );
  }
}

MenuCatgoryComponent.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array.isRequired,
  description: PropTypes.string
}
