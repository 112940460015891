import { descriptionClass, itemClass, nameClass, priceClass } from "./item.module.scss";

import PropTypes from 'prop-types';
import React from 'react';
import TextRenderer from '../text/renderer';

export default class MenuCatgoryItem extends React.Component {
  render() {
    const { name, description, price } = this.props;

    const splitPrice = price.split(',');

    console.log(description);

    return (
      <div className={itemClass}>
        <div className={nameClass}>{name}</div>
        {( description &&
          <div className={descriptionClass}>
            <TextRenderer text={description}/>
          </div>
        )}
        <div className={priceClass}>
          {splitPrice[0]}
          {( splitPrice[1] &&
            <sup>{splitPrice[1]}</sup>
          )}
        </div>
      </div>
    );
  }
}

MenuCatgoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
}
